import React from 'react';

import styled from 'styled-components';

import facebookLogo from '../images/facebook.svg';

const StyledSocial = styled.div`
    display: inline-block;

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #4267b2;

        svg path {
            fill: #4267b2;
        }
        * + * {
            margin-left: 1rem;
        }
    }
`;

const Social = () => (
    <StyledSocial>
        <a href="https://www.facebook.com/morespace.solutions/" target="_blank" rel="noreferrer">
            <span>Find us on Facebook: </span>
            <img src={facebookLogo} alt="Facebook" />
        </a>
    </StyledSocial>
);

export default Social;
