/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';

import fetch from 'isomorphic-unfetch';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import styled from 'styled-components';
import { Heading, Layer, Box, Button, Text } from 'grommet';
import Step3 from './quote/stepSimple';
import StepComplete from './quote/stepComplete';
import { Trustpilot } from './Trustpilot';

const StyledQuote = styled.section``;

export const STYLE_1 = 'Lean to';
export const STYLE_2 = 'Edwardian';
export const STYLE_3 = 'Double hipped edwardian';
export const STYLE_4 = 'Gable end';
export const STYLE_5 = 'Victorian';
export const STYLE_6 = 'Gull wing';
export const STYLE_7 = 'P shape left';
export const STYLE_8 = 'P shape right';
export const STYLE_9 = 'L shape left';
export const STYLE_10 = 'L shape right';

export const ALL_STYLES = [STYLE_1, STYLE_2, STYLE_3, STYLE_4, STYLE_5, STYLE_6, STYLE_7, STYLE_8, STYLE_9, STYLE_10];

const Quote = ({ page }) => {
    const [sendError, setSendError] = useState(false);
    const [complete, setComplete] = useState(false);
    const [step, setStep] = useState(0);
    const [valid, setValid] = useState({ formValid: false, formDirty: false });
    const [formData, setFormData] = useState({
        subject: 'Arrange a viewing',
        firstname: '',
        email: '',
        phone: '',
        street: '',
        town: '',
        postcode: '',
        message: '',
        how: '',
        conservatorySolution: 'Roof Panels',
    });

    const nextStep = () => setStep(step + 1);

    const handleClose = () => setSendError(false);

    const checkValid = () => {
        const required = ['email', 'firstname', 'street', 'town', 'postcode', 'phone'];

        const missing = required.filter((req) => !formData[req]);
        // console.log(missing);
        const isValid = missing.length === 0;

        const newValid = {
            ...valid,
            formDirty: true,
            formValid: isValid,
            invalidFields: missing,
        };
        setValid(newValid);
        // console.log({newValid});
        // console.log({email,firstname,town,postcode,marketing});
        return isValid;
    };

    const handleChange = (input) => (event) => {
        const form =
            event && typeof event === 'string'
                ? {
                      ...formData,
                      [input]: event,
                  }
                : {
                      ...formData,
                      [input]: event.target.value || event.currentTarget.value,
                  };
        setFormData(form);
        // console.log({ form });

        setTimeout(() => checkValid(), 300);
    };
    //   const getFormData = object => Object.keys(object).reduce((formData, key) => {
    //     console.log(key, object[key]);
    //     formData.append(key, object[key]);
    //     return formData;
    // }, new FormData());

    const sendEnquiry = async (form) => {
        const parsedQs = queryString.parse(window.location.search);

        // ?utm_source=downsmail.co.uk&utm_medium=referral&utm_campaign=downsmail
        form.conservatorySolution = 'Insulated Roof Panels';
        if (parsedQs && parsedQs.utm_source) {
            form.utm_source = parsedQs.utm_source;
            form.utm_medium = parsedQs.utm_medium;
            form.utm_campaign = parsedQs.utm_campaign;
        }

        form.message += '  --- FROM: https://www.conservatorypanels.com/';
        // console.log(parsedQs);
        const body = JSON.stringify(form);
        const ENDPOINT = 'https://api.morespace.solutions/quote.php';
        const req = await fetch(ENDPOINT, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', 'generate_lead', {});
        }

        if (typeof window !== 'undefined' && window.fbq) {
            window.fbq('track', 'Contact');
        }
        return req;
    };

    const checkForm = async () => {
        // console.log({formData});
        // Check we can send
        if (!checkValid()) {
            return;
        }
        // Send enquiry
        const response = await sendEnquiry(formData);
        const json = await response.json();

        const { status } = json;

        if (status === 'ok') {
            // show thank you message
            nextStep();
            setComplete(true);
        } else {
            setSendError(true);
        }
    };

    return (
        <>
            <a name="quote" id="quote" />

            <StyledQuote>
                <Box alignSelf="center" align="center" justify="center">
                    <Heading level={3} textAlign="center">
                        Please arrange a FREE no-obligation quotation
                    </Heading>
                    {/* <Trustpilot /> */}
                </Box>

                {(() => {
                    switch (step) {
                        case 0:
                            return (
                                <Step3
                                    handleChange={handleChange}
                                    complete={checkForm}
                                    isValid={valid}
                                    fullForm={formData}
                                    page={page}
                                />
                            );
                        case 1:
                            return <StepComplete />;
                        default:
                            return (
                                <Step3
                                    handleChange={handleChange}
                                    complete={checkForm}
                                    isValid={valid}
                                    fullForm={formData}
                                    page={page}
                                />
                            );
                    }
                })()}
            </StyledQuote>

            {sendError && (
                <Layer position="center" onClickOutside={handleClose} onEsc={handleClose}>
                    <Box pad="medium" gap="small" width="medium">
                        <Heading level={3} margin="none">
                            Looks Like some has gone wrong
                        </Heading>
                        <Text>
                            Sorry, we could not send your enquiry, please make sure you have completed all the required
                            fields.
                        </Text>
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            pad={{ top: 'medium', bottom: 'small' }}
                        >
                            <Button
                                label={
                                    <Text color="white">
                                        <strong>ok</strong>
                                    </Text>
                                }
                                onClick={handleClose}
                                primary
                                color="status-critical"
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    );
};

export default Quote;
