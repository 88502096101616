import React from 'react';

import styled from 'styled-components';

import { Box } from 'grommet';
import Social from './social';

import plogo from '../images/leo-logo.svg';
import Block from './block';
import HtmlContent from './htmlContent';

const Pimage = styled.img`
    max-width: 220px;
    height: auto;
    margin-bottom: 30px;
`;

const StyledBanner = styled(Box)`
    background-color: #fff;

    .content {
        width: 100%;
        max-width: 1200px;
        text-align: center;
    }
    .img {
        max-width: 300px;
        display: block;
        position: relative;
        margin: 0 auto;
        text-align: center;
    }

    .call {
        font-size: 1rem;
        white-space: nowrap;

        @media (min-width: 380px) {
            font-size: 1.25rem;
        }

        display: block;
        color: #222;
        a {
            font-weight: 900;
            color: inherit;
            font-size: 1.125rem;
            @media (min-width: 340px) {
                font-size: 1.4rem;
            }
        }
    }
`;

const Partner = () => (
    <Block justify="center">
        <StyledBanner>
            <HtmlContent>
                <div className="img">
                    <Pimage src={plogo} alt="Leo's" />
                    <br />
                    <span className="web">
                        <a href="https://www.conservatorypanels.com">www.conservatorypanels.com</a>
                    </span>
                    <br />
                    <span className="email">
                        <a href="mailto:leospropertysolutions@mail.com">leospropertysolutions@mail.com</a>
                    </span>
                    <br />
                    <span className="byline">creating Improving properties throughout Sussex, Surry & Kent throughout the Southeast and now the UK</span>
                    <br />
                    <span className="call">
                        Sussex: <a href="tel:08009991394">0800 999 1394</a>
                    </span>
                
                    <br />
                    <Social />
                </div>
            </HtmlContent>
        </StyledBanner>
    </Block>
);

export default Partner;
